<template>
  <div>
    <!-- bg -->
    <div class="bg_gray"></div>
    <div class="centerbox">

    <van-pull-refresh v-model="isLoading" style="min-height: 100vh;" @refresh="onRefresh">
      <template v-if="ishow">
       <van-empty
           class="custom-image"
           :image="require('@/assets/images/pic_no information@2x.png')"
           description="暂无数据!"
         />
      </template>
        <!-- 我的保单 -->
        <div  v-if="!ishow">
          <div class="msgbox" v-for="items in listdata" :key="items.id">  
           <h2 class="flex-between h2">
            <div>太平洋货物运输险</div>
            <!--  "status":1,//保险状态   0 生效中/保障中  1 已失效/已完成  2 待生效 -->
            <div
              class=""
              :class="
                items.status == 0
                  ? 'statusbox'
                  : items.status == 1
                  ? 'overstatusbox'
                  : 'onstatusbox'
              "
            >
              {{
                items.status == 0
                  ? "保障中"
                  : items.status == 1
                  ? "已失效"
                  : "待生效"
              }}
            </div>
          </h2>
          <div class="flex">
            <div>被保人：</div>
            <div>{{ items.name }}</div>
          </div>
          <div class="flex">
            <div>有效期限：</div>
            <div>{{ items.insuranceDuration }}</div>
          </div>
          <div class="flex">
            <div>投保额度：</div>
            <div>{{ items.insuranceMoney }}元</div>
          </div>
          <div class="flex-between">
            <div><i class="el-icon-more"></i></div>
            <div class="flexs">
              <!--  -->
              <div class="yellowmin">
                <router-link :to="{ name: 'claimExplanation' }">
                  理赔说明</router-link
                >
              </div>
              <div class="gosendproduct">
                <router-link
                  :to="{
                    name: 'claimDetails',
                    query: { orderno: items.orderNo }
                  }"
                >
                  保单详情</router-link
                >
              </div>
            </div>
          </div>
          </div>
        </div>
    </van-pull-refresh>
      
    </div>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
// 保险单列表
import * as InsuranceList from "@/utils/api";
export default {
  data() {
    return {
      listdata: [],
      isLoading:false, //下拉刷新
      ishow:true
    };
  },
  mounted() {
    InsuranceList.queryInsuranceList().then(res => {
      console.log('res1',res);
      if (res.code == 0) {
         this.listdata = res.data;
          if (this.listdata.length == 0) {
             this.ishow = true
          }else{
             this.ishow = false
          }
      } else {
        this.$toast.fail(res.msg);
        this.ishow = true
      }
 
    });
  },
  methods: {
    onRefresh(){
      InsuranceList.queryInsuranceList().then(res => {
        if (res.code == 0) {
          this.listdata = res.data;
            if (this.listdata.length == 0) {
               this.ishow = true
            }else{
               this.ishow = false
            }
        } else {
          this.$toast.fail(res.msg);
          this.ishow = true
        }
        this.isLoading = false;
      })
    }
  },
  components: {
    headbox
  }
};
</script>
<style lang="scss" scoped="true">
::v-deep{
      .van-empty__image{
        width: 420px;
        height: 260px;
    }
}
.gosendproduct {
  background: #ff2d2e;
  color: #fff;
  border-radius: 32px;
  width: 160px;
  height: 64px;
  text-align: center;
  line-height: 64px;
  margin-left: 26px;
  font-size: 28px;
}
.flexs {
  display: flex;
}
.bg_gray {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #f7f7f7;
}
.centerbox {
  position: relative;
  z-index: 0;
}
.statusbox {
  color: #00b46a;
  background: #d5f9ea;
  font-size: 28px;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: normal;
}
.onstatusbox {
  color: #ff2d2e;
  background: #fff0f0;
  font-size: 28px;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: normal;
}
.overstatusbox {
  color: #999999;
  background: #f0f0f0;
  font-size: 28px;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: normal;
}
.flex-between {
  align-items: center;
  i {
    font-size: 48px;
    color: #cccccc;
  }
}

.yellowmin {
  color: #ff2d2e;
  border: 1px solid #ff2d2e;
  border-radius: 30px;
  font-size: 28px;
  width: 160px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  // margin-bottom: 30px;
}
.msgbox {
  background: #fff;
  padding: 0 30px 30px;
  width: 690px;
  font-size: 28px;
  border-radius: 12px;
  box-shadow: 0 0 0 3px #ececec;
  margin: 25px auto 30px;
  z-index: 1;

  h2 {
    font-size: 32px;
    margin-bottom: 30px;
    padding-top: 30px;
  }
  .flex {
    margin-bottom: 30px;
    div:nth-child(1) {
      color: #cccccc;
      width: 140px;
    }
    div:nth-child(2) {
      color: #333333;
    }
  }
}
.tab_title {
  background: #f7f7f7;
  color: #999999;
  font-size: 26px;
  span {
    padding: 20px 0px 10px;
  }
  .active {
    position: relative;
    // color:#FFB606;
    &::after {
      content: "";
      width: 100%;
      height: 5px;
      border-radius: 4px;
      background: #ffb606;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
.iconbox {
  width: 125px;
}
.titlebox {
  font-size: 30px;
}
.msg_list {
  padding: 30px 0;
  border-bottom: 1px solid #cdcdcd;
  .time {
    position: absolute;
    right: 0;
    color: #b3b3b3;
    font-size: 24px;
    font-weight: normal;
    top: 35px;
    right: 30px;
  }
}
.details {
  font-size: 24px;
  margin-top: 15px;
  color: #b3b3b3;
}
</style>
