var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg_gray" }),
    _c(
      "div",
      { staticClass: "centerbox" },
      [
        _c(
          "van-pull-refresh",
          {
            staticStyle: { "min-height": "100vh" },
            on: { refresh: _vm.onRefresh },
            model: {
              value: _vm.isLoading,
              callback: function($$v) {
                _vm.isLoading = $$v
              },
              expression: "isLoading"
            }
          },
          [
            _vm.ishow
              ? [
                  _c("van-empty", {
                    staticClass: "custom-image",
                    attrs: {
                      image: require("@/assets/images/pic_no information@2x.png"),
                      description: "暂无数据!"
                    }
                  })
                ]
              : _vm._e(),
            !_vm.ishow
              ? _c(
                  "div",
                  _vm._l(_vm.listdata, function(items) {
                    return _c("div", { key: items.id, staticClass: "msgbox" }, [
                      _c("h2", { staticClass: "flex-between h2" }, [
                        _c("div", [_vm._v("太平洋货物运输险")]),
                        _c(
                          "div",
                          {
                            class:
                              items.status == 0
                                ? "statusbox"
                                : items.status == 1
                                ? "overstatusbox"
                                : "onstatusbox"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  items.status == 0
                                    ? "保障中"
                                    : items.status == 1
                                    ? "已失效"
                                    : "待生效"
                                ) +
                                " "
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "flex" }, [
                        _c("div", [_vm._v("被保人：")]),
                        _c("div", [_vm._v(_vm._s(items.name))])
                      ]),
                      _c("div", { staticClass: "flex" }, [
                        _c("div", [_vm._v("有效期限：")]),
                        _c("div", [_vm._v(_vm._s(items.insuranceDuration))])
                      ]),
                      _c("div", { staticClass: "flex" }, [
                        _c("div", [_vm._v("投保额度：")]),
                        _c("div", [_vm._v(_vm._s(items.insuranceMoney) + "元")])
                      ]),
                      _c("div", { staticClass: "flex-between" }, [
                        _c("div", [_c("i", { staticClass: "el-icon-more" })]),
                        _c("div", { staticClass: "flexs" }, [
                          _c(
                            "div",
                            { staticClass: "yellowmin" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "claimExplanation" } } },
                                [_vm._v(" 理赔说明")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "gosendproduct" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "claimDetails",
                                      query: { orderno: items.orderNo }
                                    }
                                  }
                                },
                                [_vm._v(" 保单详情")]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  }),
                  0
                )
              : _vm._e()
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }